import React from "react";
import "./home.css";
import CategoryListing from "./../categoryListing/categoryListing";



export class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            pagetitle: ""
        }
    }
    
    componentDidMount() {       
    } 
    
    render() {
        return(
        <div>           
            <div className="container">
                <div className="homeCategoryListing">                
                <CategoryListing></CategoryListing>
                </div>

                <div className="col-md-12 horarios">
                    <p className="apertura">Horario de apertura</p>
                    <div className="hours">                
                            Miercoles a Viernes: 11:00 - 14:00 y 16:00 - 19:30<br/>
                            Sabado y Domingo: 16:00 - 19:30 <br/>  
                            Lunes y martes cerrado                                          
                    </div>
                </div>
                   
            
             <div className="col-md-12 horarios">
                    <p className="apertura">Reposteria vegana</p>
                    <div className="hours">                        
                    Somos una empresa 100% vegana con varios objetivos: <br></br>
                    Lo vegano no tiene por que ser caro, y por ser vegano, <br></br> 
                    cualquier tarta o dulce que se pueda elaborar no deja de ser delicioso<br></br>
                    </div>
                </div>

                <div className="col-md-12 horarios">
                            <p className="apertura">¿Que es vegano?</p>
                            <div className="hours">                        
                            Significa que todo en nuestro obrador es 100% vegetal. <br></br>
                            No contiene productos de animal como por ejemplo leche, miel, huevo, manteca de cerdo etc.<br></br> 
                            Contienen gluten, frutos secos y otros ingredientes de origen vegetal.<br></br>
                    </div>
                </div>
                </div>
        </div>
        );
    };
}

export default Home;